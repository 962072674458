$buttons-gap: 3px;

.button {
  display: flex;
  min-width: 20px;
  height: 40px;
  padding: 0;
  color: #10006a;
  font-weight: 600;
  border: none !important;
}

.buttonIncrease {
  margin-bottom: $buttons-gap;
  border-radius: 6px 6px 0 0;
  margin-right: 10px;
}

.buttonDecrease {
  border-radius: 0 0 6px 6px;
}

.input {
  fieldset {
    display: none;
  }
}
