.selectCommon {
  width: 156px;
  max-width: 156px;
  max-height: 40px;
}


.activeTabButton {
  color: #00e1d4 !important;
  border: none;
  border-bottom: 1px solid #00e1d4;
  transition: none;
  border-radius: 0px;
}