.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 27rem;
  padding: 20px 20px 0.5rem;
  border-radius: 4px;
  background-color: #19194a;
  transform: translate(-50%, -50%);
}

.mutedText {
  color: #adadd0 !important;
}