.datePicker {
  border-radius: 5px;

  :global(.MuiOutlinedInput-notchedOutline) {
    border-color: transparent;
    font-weight: 600 !important;
  }

  :global(.MuiInputBase-input) {
    font-weight: 600;
  }
}

.datePickerIcon {
  color: #bebeff;
}

:global(.MuiCalendarPicker-root) {
  color: white;

  :global(.MuiTypography-root) {
    color: white;
  }

  :global(.MuiPickersDay-root:hover) {
    background-color: #10006a;
    color: white;
  }
}

.datePickerIconDisabled {
  opacity: 0.3;
}
