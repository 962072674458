.sidebar {
  border-right: none;
  // background-color: #090a39;
}

.listItem {
  color: #adadd0 !important;
}

.arrowIcons {
  color: #adadd0 !important;
  font-size: 35px;
  z-index: 1203;
  cursor: pointer;
}

.arrowIconsWrapper {
  justify-content: flex-end;
  padding-right: 15px;
  height: 100px;
  align-items: center;
  display: flex;
  position: fixed;
  z-index: 1203;
  margin-left: 215px;

}

.activeListItem {
  position: relative;
  color: #fff !important;
  font-weight: bold;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 4px;
    content: '';
    background-color: #6ffffd;
  }
}

.logo {
  height: 100px;
  width: 140px;
  margin-left: 1rem;
  cursor: pointer;
  z-index: 1203;
  position: fixed;
}

@media only screen and (max-width: 600px) {
  .arrowIconsWrapper {
    justify-content: flex-end;
    padding-right: 15px;
    height: 100px;
    align-items: center;
    display: flex;
    position: fixed;
    z-index: 1203;
    margin-left: 165px;
  
  }
}