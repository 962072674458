.button {
  width: 18%;
  background-color: transparent;
}

.label {
  color: #bebeff;
}

.datePicker {
  border-radius: 5px;

  :global(.MuiOutlinedInput-notchedOutline) {
    border-color: transparent;
    font-weight: 600 !important;
  }

  :global(.MuiInputBase-input) {
    font-weight: 600;
  }
}

.datePickerIcon {
  color: #bebeff;
}

.section {
  border-bottom: 1px solid rgba(111, 255, 253, 0.5);

  &:last-child {
    border-bottom: 0;
  }
}
