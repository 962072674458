.header {
  height: 100px;
  // background-color: #0a0b43;
}

.header_icon_image {
  margin-right: 10px;
}

.header_text {
  opacity: 0.6;
  font-size: 14px;
  font-weight: 500;
}

#issued_icon {
  margin-right: 10px;
}

.popper {
  width: 650px;
}

.issued_tokens_image {
  border-radius: 50%;
  max-width: 225px;
  aspect-ratio: 1/1;
}