.addButton {
  width: 100%;
  background-color: var(--background-color);
  border-color: var(--background-color);
  color: rgb(255, 255, 255, 0.4);
  margin-top: 10px;
  font-size: 22px;
  height: 40px;
  padding: 0px;
}

.footerButton {
  width: 20%;
  background-color: transparent;
  font-weight: bold;
}


.previewImg {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
}

#var {
  color: #00e1d4;
  font-size: 18px;

}

.th {
  padding: 0px 10px 20px 0px;
  border-bottom: none;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.th_scroll {
  padding: 0px 10px 10px 0px;
  border-bottom: none;
}