.tableContainer {
  td {
    color: #bebeff;
  }
}

.tableCellHead {
  font-weight: bold;
  border-bottom: none;
}

.tableCell {
  border-bottom: 1px solid rgba(173, 173, 208, 0.2);
}

.progressBar {
  height: 10px;
  margin-top: 5px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.2);
}

.bar {
  border-radius: 6px;
  background-image: linear-gradient(to right, #10006a, #6ffffd 84%);
}
