.cell {
  border-color: #00e1d4;
}

.icon {
  color: #00e1d4;
}

.th {
  border-bottom: none;
  font-weight: bold;
}