@use "shared";

@tailwind components;
@tailwind utilities;

html {
  // background: #1f1d47;
  background-image: var(--background-gradient);

}

:root {
  --background-gradient: linear-gradient(to top, #05160e, #092216); // Default background gradient
  --background-color: #1a1946;
}

body {
  margin: 0;
  font-family: shared.$default-font-family !important;
  // background-image: linear-gradient(to top, #1f1d47, #0a0b43);
  // background-image:linear-gradient(to top, #05160e, #092216);
  background-image: var(--background-gradient);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.background_gradient_color {
  background-image: linear-gradient(180deg, hsla(246, 64%, 27%, 1), hsla(247, 71%, 25%, 1)) !important;
}



::placeholder {
  opacity: 1 !important;
  color: #adadd0 !important;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}


.super {
  font-size: 10px;
  vertical-align: super;
}

.sub {
  vertical-align: sub;
  font-size: 10px;
}

.super_dot {
  vertical-align: bottom;
  font-size: 20px;
}