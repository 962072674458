.header {
  background-color: #0a0b43;
}

.selectIcon {
  color: #fff;
}

.switchTrack {
  background-color: #fff;
}