.selectLabel {
    margin-top: -5px;
    color: #adadd0 !important;
}

.sphere {
    border-radius: 50%;
    max-width: 225px;
    height: auto;
    background-color: rgb(255 255 255 / 10%);
    margin: 30px auto 0px auto;
    padding: 23px 22px 22px 23px;
    aspect-ratio: 1/1;
}

.innerSphere {
    border-radius: 50%;
    max-width: 180px;
    height: auto;
    background-color: var(--background-color);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
}

.previewImg {
    border-radius: 50%;
    max-width: 180px;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    aspect-ratio: 1/1;
}

.imageIcon {
    font-size: 42px;
    color: #bebeff;
    opacity: 0.5;
}