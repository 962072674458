.admissible {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.admissible img {
    margin-right: auto;
    margin-left: auto;
}

.validity {
    margin-top: 12px;
}

.validityBlock {
    display: flex;
    align-items: center;
    color: white;
}

.validityBlock p {
    font-size: 14px;
    color: white;
    margin-right: auto;
    margin-left: auto;
}

.validityComment {
    margin-top: 12px;
}

.validityCommentBlock {
    display: flex;
    align-items: center;
}

.validityCommentBlock p {
    font-size: 10px;
    color: var(--main-gray-color);
    margin-right: auto;
    margin-left: auto;
}

.validityCommentBlock img {
    padding-right: 4px;
}

.greenBlock {
    margin-top: 12px;
    font-weight: 400;
    border: solid 1px #6ffffd;
    color: #6ffffd;
    border-radius: 6px;
    cursor: pointer;
}

.greenText {
    display: flex;
    align-items: center;
}

.greenText p {
    font-size: 14px;
    color: #6ffffd;
    margin-right: auto;
    margin-left: auto;
    background-color: transparent;
}